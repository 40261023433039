<template>
  <div>
    <v-icon
      color="green"
      small
      class="mr-2"
      @click="getUserChild1"
      :disabled="dataDelete.length > 0"
      v-if="item.objChild > 0"
      >mdi-eye
    </v-icon>
    <v-icon color="green" small class="mr-2" disabled v-else
      >mdi-eye-off
    </v-icon>
    <v-icon
      color="indigo"
      small
      class="mr-2"
      @click="onDialogChangePassword"
      :disabled="dataDelete.length > 0"
      v-if="checkUserPermission('CHANGE-PASSWORD')"
    >
      mdi-account-convert
    </v-icon>

    <v-icon
      color="orange"
      small
      class="mr-2"
      @click="updateUser"
      :disabled="dataDelete.length > 0"
      v-if="checkUserPermission('EDIT')"
      >mdi-pencil
    </v-icon>

    <v-icon
      color="purple"
      small
      class="mr-2"
      @click="getAllLimitBetting"
      :disabled="dataDelete.length > 0"
      v-if="
        checkUserPermission('EDIT') &&
          userp.role_id != '611532b19c6e77ad0fd50c1c' &&
          userp.role_id != '61829dc7e64b1b17688326d5' &&
          userp.role_id != '61829dd1e64b1b17688326df' &&
          userp.role_id != '61829de6e64b1b17688326f3' &&
          userp.role_id !='61bf5eb0c73d7eea8634fbf4'
      "
      >mdi-cogs
    </v-icon>

    <v-icon
      color="purple"
      small
      class="mr-2"
      @click="updateCompanyLimitBetting"
      :disabled="dataDelete.length > 0"
      v-if="
        checkUserPermission('EDIT') &&
          item.role_id =='612a3fcc1a1fcead8871e822' &&
          userp.role_id == '611532b19c6e77ad0fd50c1c'
      "
      >mdi-cogs
    </v-icon>
    <v-icon
      color="orange"
      small
      class="mr-2"
      @click="upConfigUser"
      :disabled="dataDelete.length > 0"
       v-if="userp.role_id == '61829dc7e64b1b17688326d5' || userp.role_id == '61829dd1e64b1b17688326df' || userp.role_id == '61829de6e64b1b17688326f3'"
      >mdi-account-cog
    </v-icon>
    <v-icon
      color="red"
      small
      @click="dialogDelete = true"
      :disabled="dataDelete.length > 0"
      v-if="checkUserPermission('DELETE')"
    >
      mdi-delete
    </v-icon>
    <v-icon
      color="green"
      small
      class="mr-2"
      @click="refreshUser"
      v-if="userp && (userp.role_id == '61829dc7e64b1b17688326d5' || userp.role_id == '61829dd1e64b1b17688326df')"
      >mdi-refresh
    </v-icon>
    <dialog-delete
      :darkmode="darkmode"
      :dialog-delete="dialogDelete"
      v-on:closeDelete="cancelDelete"
      v-on:deleteItemConfirm="deleteItem"
    />

    <dialog-change-password
      :darkmode="darkmode"
      :dialog="dialogChangePassword"
      :is-validate="validPassword"
      :user="user"
      :loading="loading"
      v-on:submit="onChangePassword"
      v-on:onCloseDialog="closeDialogChangePassword"
    />
    <config-user-data
       v-if="userp.role_id == '61829dc7e64b1b17688326d5' || userp.role_id == '61829dd1e64b1b17688326df' || userp.role_id == '61829de6e64b1b17688326f3'"
      :darkmode="darkmode"
      :dialog="dialogConfigUserData"
      :is-validate="validPassword"
      :dataConfig="configUserData"
      :loading="loading"
      :limitConfig="limitConfig"
      v-on:submit="onChangeConfigUser"
      v-on:onCloseDialog="closeConfigUserDialog"
   :userp="userp"
    />

    <user-list1
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild"
      :data-request="data1"
      :userp="userp"
      v-on:getData1="getUserChild1"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
  <!-- developer limit betting -->
    <config-high-level-user
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUser"
      :is-validate="validPassword"
      :dataConfig="limitHighLevelUser"
      :limitConfig="limitConfig"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUser"
      v-on:onCloseDialog="closeConfigHighLevelUserDialog"
      :userp="userp"
    />

    <!-- developer limit betting -->
    <config-high-level-user-company
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUserCompany"
      :dataConfig="configHighLevelUserCompany"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUserCompany"
      v-on:onCloseDialog="closeConfigHighLevelUserCompanyDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import DialogDelete from "../../../../components/shared/notify/dialogDelete";
import DialogChangePassword from "../DialogChangePassword";
import configUserData from "../ConfigUserData";
import UserList1 from "../userList/Dialog1";
import ConfigHighLevelUser from "../LimitBetting";
import ConfigHighLevelUserCompany from "../LimitBettingForDeveloper";
export default {
  components: { ConfigHighLevelUser, DialogChangePassword, DialogDelete, UserList1, configUserData,     ConfigHighLevelUserCompany },
  props: [
    "darkmode",
    "item",
    "params",
    "dataDelete",
    "userPermission",
    "userp",
  ],
  data() {
    return {
      validPassword: true,
      user: {
        _id: null,
        user_name: null,
        password: null,
      },
      dialogListUser: false,
      loading: false,
      dialogChangePassword: false,
      dialogConfigUserData: false,
      dialogDelete: false,
      userChild: [],
      data1: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null,
      },
      limitData: {
        currency_type : this.item.currency_type,
        descendingFormat: [true],
        sortByFormat: ['_id'],
        descending: true,
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["default_bet_a_fight", "default_bet_win_a_day", "agent_bet_a_fight", "agent_bet_win_a_day"],
        search: ''
      },
      limitConfig:{},
      configUserData: {

      },
      dialogConfigHighLevelUser: false,
      limitHighLevelUser: {},
      configHighLevelUserCompany: {},
      dialogConfigHighLevelUserCompany:false
    };
  },
  watch: {
    configUserData: function() {},
    getConfigUserData: function(){}
  },
  computed: {
    ...mapGetters("$_user", ["getCurrentUser"]),
    ...mapGetters("$_configUser", ["getConfigUserData"])
    
  },
  methods: {
    async updateUser() {
      await this.fetchCurrentUser(this.item);
      await this.fetchAllRolesDependOnUser();
      this.fetchPopUp(true);
    },

    async upConfigUser() {
      let data = { user_id: this.item._id };
      this.$request
        .post({
          url: "configUser/getConfigUserByUserId",
          data: data,
        })
        .then((res) => {
          this.configUserData = res.data.data.object;
          this.getLimitData();
          this.dialogConfigUserData = true;
        });

      // this.fetchPopUp(true);
    },

    cancelDelete() {
      this.dialogDelete = false;
    },

    async deleteItem() {
      await this.deleteUser({
        vm: this,
        data: { deleteItems: [{ _id: this.item._id }] },
      });
      await this.fetchListUsers(this.params);
      this.dialogDelete = false;
    },
    onDialogConfigUserData() {
      this.dialogConfigUserData = true;
    },
    onDialogChangePassword() {
      this.user = {
        _id: this.item._id,
        user_name: this.item.user_name,
        password: null,
      };
      this.dialogChangePassword = true;
    },

    async onChangePassword() {
      this.loading = true;
      if (this.user._id) {
        await this.changePassword({ vm: this, data: this.user });
        this.loading = false;
        this.dialogChangePassword = false;
      }
    },

    async onChangeConfigUser() {
      this.loading = true;
      this.configUserData.total_bet_a_fight = parseInt(
        this.configUserData.total_bet_a_fight
      );
      this.configUserData.betting_frequency = parseInt(
        this.configUserData.betting_frequency
      );
      this.configUserData.total_bet_win_a_day = parseInt(
        this.configUserData.total_bet_win_a_day
      );
      this.configUserData.amount_min = parseInt(
        this.configUserData.amount_min
      );
      this.configUserData.amount_max = parseInt(  
        this.configUserData.amount_max
      );
      
      if (!this.configUserData.is_default && this.configUserData._id ) {
        
        await this.updateConfigUser({ vm: this, d: this.configUserData });
        this.loading = false;
        this.dialogConfigUserData = false;
      } else if(this.configUserData.is_default && this.configUserData._id){
        
        this.configUserData.user_id = this.item._id;
        await this.createConfigUser({ vm: this, d: this.configUserData });
        this.loading = false;
        this.dialogConfigUserData = false;
      }
    },

    closeDialogChangePassword() {
      this.dialogChangePassword = false;
    },

    closeConfigUserDialog() {
      this.dialogConfigUserData = false;
    },
    getUserChild1() {
      this.data1.parentId = this.item._id;
      this.data1.sortByFormat.length < 1
        ? (this.data1.sortBy = "_id")
        : (this.data1.sortBy = this.data1.sortByFormat[0]);
      this.data1.descendingFormat.length > 0
        ? (this.data1.descending = this.data1.descendingFormat[0])
        : (this.data1.descending = true);
      this.$request
        .post({
          url: "users/getAllByUserId",
          data: this.data1,
        })
        .then((res) => {
          if (res.data.code) {
            this.dialogListUser = true;
            this.userChild = res.data.data;
          }
        });
    },
    // async getLimitData(){
    //   const response = await  this.$request.post({
    //     url: 'limitBetting/getAll',
    //     data: this.limitData

    // });
    // this.limitConfig = response.data.data.object;
    // },
    async refreshUser(){
      let data = { user_id: this.item._id };
      await this.$request
          .post({
          url: "sync-module/refreshSyncData",
          data: data,
          })
          .then((res) => {
            console.log("res",res.data);
          if (res.data.code) {
              
              this.$emit("child-checkbox", data);
          }
          });
    } , 
    async getAllLimitBetting() {
      let data = { currency_type: this.item.currency_type, _id: this.item._id };
      this.$request
        .post({
          url: "limitBetting/getAll",
          data: data,
        })
        .then((res) => {
          this.limitHighLevelUser = res.data.data.object;
          this.getLimitData();
          this.dialogConfigHighLevelUser = true;
        });

    },

    formatHighLevelUserData(){
      let data = this.limitHighLevelUser;
      data.currency_type =  this.item.currency_type;

      data.betting_frequency = parseInt(
        this.limitHighLevelUser.betting_frequency
      );

      data.total_bet_win_a_day = parseInt(
        this.limitHighLevelUser.total_bet_win_a_day
      );
      data.total_bet_a_fight = parseInt(
        this.limitHighLevelUser.total_bet_a_fight
      );
       data.amount_min = parseInt(
        this.limitHighLevelUser.amount_min
      );
       data.amount_max = parseInt(
        this.limitHighLevelUser.amount_max
      );
    return data;
    },

    async onChangeConfigHighLevelUser() {
      this.loading = true;
      let formatData = this.formatHighLevelUserData();
      if (formatData._id) {
        await this.updateLimitSetting({
          vm: this,
          data: formatData
        });
        this.loading = false;
        this.dialogConfigHighLevelUser = false;
      }
    },

    async onChangeConfigHighLevelUserCompany() {
      this.loading = true;
      // លុយថៃ
      
      this.configHighLevelUserCompany.objectTbs[0].currency_type = 3;

      this.configHighLevelUserCompany.objectTbs[0].total_bet_a_fight = parseInt(
      this.configHighLevelUserCompany.objectTbs[0].total_bet_a_fight
      );

      this.configHighLevelUserCompany.objectTbs[0].betting_frequency = parseInt(
      this.configHighLevelUserCompany.objectTbs[0].betting_frequency
      );

     this.configHighLevelUserCompany.objectTbs[0].total_bet_win_a_day = parseInt(
        this.configHighLevelUserCompany.objectTbs[0].total_bet_win_a_day
      );
      if (this.configHighLevelUserCompany.objectTbs[0]._id) {
        await this.updateLimitSetting({
          vm: this,
          data: this.configHighLevelUserCompany.objectTbs[0],
        });
      }
          // លុយដូល្លា
      this.configHighLevelUserCompany.objectUsd[0].currency_type = 1;

      this.configHighLevelUserCompany.objectUsd[0].betting_frequency = parseInt(
      this.configHighLevelUserCompany.objectUsd[0].betting_frequency
      );

      this.configHighLevelUserCompany.objectUsd[0].total_bet_a_fight = parseInt(
      this.configHighLevelUserCompany.objectUsd[0].total_bet_a_fight
      );
     this.configHighLevelUserCompany.objectUsd[0].total_bet_win_a_day = parseInt(
        this.configHighLevelUserCompany.objectUsd[0].total_bet_win_a_day
      );
      if (this.configHighLevelUserCompany.objectUsd[0]._id) {
        await this.updateLimitSetting({
          vm: this,
          data: this.configHighLevelUserCompany.objectUsd[0],
        });
      }
       // លុយ ខ្មែរ
      this.configHighLevelUserCompany.objectKh[0].currency_type = 2;

      this.configHighLevelUserCompany.objectKh[0].betting_frequency = parseInt(
      this.configHighLevelUserCompany.objectKh[0].betting_frequency
      );

      this.configHighLevelUserCompany.objectKh[0].total_bet_a_fight = parseInt(
      this.configHighLevelUserCompany.objectKh[0].total_bet_a_fight
      );
     this.configHighLevelUserCompany.objectKh[0].total_bet_win_a_day = parseInt(
        this.configHighLevelUserCompany.objectKh[0].total_bet_win_a_day
      );
      
      if (this.configHighLevelUserCompany.objectKh[0]._id) {
          await this.updateLimitSetting({
          vm: this,
          data: this.configHighLevelUserCompany.objectKh[0],
        });
        this.loading = false;
        this.dialogConfigHighLevelUserCompany = false;
        } 
    },

    closeConfigHighLevelUserDialog() {
      this.dialogConfigHighLevelUser = false;
    },
    closeConfigHighLevelUserCompanyDialog() {
      this.dialogConfigHighLevelUserCompany = false;
    },

    updateCompanyLimitBetting(){
      let data = {user_id: this.item._id };
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          console.log(res);
          this.configHighLevelUserCompany = res.data.data;
          this.dialogConfigHighLevelUserCompany = true;
        });
    },
    async getLimitData() {
      let data = {
        currency_type: this.item.currency_type,
        _id: this.item.parent_id,
      };
      const response = await this.$request.post({
        url: "limitBetting/getParentLimitBetting",
        data: data,
      });
      this.limitConfig = response.data.data.object;
    },

    ...mapMutations("$_configUser", ["UPDATE_CONFIG_USER_DATA"]),
    ...mapActions("$_configUser", [
      "fetchGetAllConfigUser",
      "fetchGetConfigUserByUserId",
      "updateLimitSetting",
      "updateConfigUser",
      "createConfigUser"
    ]),

    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_user", [
      "fetchPopUp",
      "fetchCurrentUser",
      "deleteUser",
      "fetchListUsers",
      "changePassword",
    ]),
  },
};
</script>
