<template>
  <div v-if="dialog && users">
    <v-dialog :value="dialog" max-width="1200" persistent transition="dialog-bottom-transition">
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            {{ $t('title.user_list') }} 3
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
              v-model="dataRequest.search"
              append-icon="mdi-magnify"
              :label="$t('input.search')"
              single-line
              @input="$emit('getData3')"
          />
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="_id"
              
              :headers="headers"
              :footer-props="footerProps"
              :items="users ? users.object : []"
              :server-items-length="users ? users.objectCount : null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="$emit('getData3')"
              @update:items-per-page="$emit('getData3')"
              @update:sort-desc="$emit('getData3')"
              mobile-breakpoint="0"
          >
                    <template v-slot:[`item.updateConfigUser`]="{ item }">
              <v-btn  v-if="item.role_id !='61829df5e64b1b1768832700' && item.role_id !='61829dfee64b1b1768832709' && item.role_id !='61829e05e64b1b1768832712' " color="primary" @click="getAllLimitBetting(item)" :disabled="userp && !userp.enable? true:false">
                <v-icon>mdi-cogs</v-icon>
              </v-btn>
              <v-btn  v-if="item.role_id =='61829df5e64b1b1768832700' || item.role_id =='61829dfee64b1b1768832709' || item.role_id =='61829e05e64b1b1768832712' " color="warning" @click="upConfigUser(item)" :disabled="userp && !userp.enable? true:false">
                <v-icon>mdi-cogs</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.enable`]="{ item }">
             <v-btn :color="item.enable ? 'success' : 'error'" @click="_openDialogSuspend(item)" :disabled="userp && !userp.enable? true:false">
            {{ item.enable ? "Active" : "Inactive" }}
            </v-btn>
            </template>
              <template v-slot:[`item.balance`]="{ item }">
                  <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && (item.currency_type===1 || item.currency_type===3) " >
                  {{ currencyFormatUsd(item.balance)}}
                </span>
                <span   v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===2" >
                  {{ currencyFormatKh(item.balance)}}
                </span>
              
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="green"
                      small class="mr-2"
                      @click="getUserChild4(item)"
                      v-if="item.objChild > 0"
              >mdi-eye
              </v-icon>
              <v-icon color="green"
                      small class="mr-2"
                      disabled
                      v-else
              >mdi-eye-off
              </v-icon>
              <v-icon
                color="indigo"
                small
                class="mr-2"
                @click="onDialogChangePassword(item)"
                v-if="userp.role_id === '611532b19c6e77ad0fd50c1c'"
              >
                mdi-account-convert
               
              </v-icon>
              <dialog-change-password
                :dialog="dialogChangePassword"
                :is-validate="validPassword"
                :user="user"
                :loading="loading"
                v-on:submit="onChangePassword"
                v-on:onCloseDialog="closeDialogChangePassword"
              />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <user-list4
  
        :dialog="dialogListUser"
        :users="userChild4"
        :data-request="data4"
         :userp="userp"
        v-on:getData4="getUserChild4(item)"
        @onCloseDialog="dialogListUser = !dialogListUser"
    />
    <v-dialog v-model="suspendDialog" persistent max-width="500px">
      <v-card >
        <v-card-title class="text-h5 py-5">
         <!-- {{ targetChannel && targetChannel.enable ?  'បិទដំណើរការ Channel ' : 'បើកដំណើរការ Channel '}}
          -->
          ok ?
        </v-card-title>
        <v-card-actions>
          <v-btn color="blue-grey"    class="ma-2 white--text"  @click="_closeDialogSuspend">Cancel</v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn     class="ma-2 white--text" :color="targetChannel && targetChannel.enable ?  'error':'success'" @click="_takeAction">{{targetChannel && targetChannel.enable ? "Inactive Channel" :"Active Channel"}}</v-btn> -->
          <v-btn  class="ma-2 white--text" :color="selectedUser && selectedUser.enable ?  'error':'success'"
          @click="_updateUserSuspendStatus()">{{selectedUser && selectedUser.enable ? "Inactive Channel" :"Active Channel"}}</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
      <loader
      v-if="this.waiting == true"
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
     <config-user-data
  
      :dialog="dialogConfigUserData"
      :dataConfig="configUserData"
      :loading="loading"
      :limitConfig="limitConfig"
      v-on:submit="onChangeConfigUser"
      v-on:onCloseDialog="closeConfigUserDialog"
      :userp="userp"
    />

    <config-high-level-user

      :dialog="dialogConfigHighLevelUser"
      :dataConfig="limitHighLevelUser"
      :limitConfig="limitConfig"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUser"
      v-on:onCloseDialog="closeConfigHighLevelUserDialog"
            :userp="userp"
    />
  </div>
</template>

<script>
import UserList4 from "./Dialog4";
import configUserData from "../ConfigUserData.vue";
import ConfigHighLevelUser from "../LimitBetting";
import { mapActions } from "vuex";
import DialogChangePassword from "../DialogChangePassword";

export default {
  components: {UserList4, configUserData,
    ConfigHighLevelUser, DialogChangePassword},
  props: [ "dialog", "users", "dataRequest","userp"],
  data: () => {
    return {
      validPassword: true,
      dialogListUser: false,
      userChild4: [],
      footerProps: {
        'items-per-page-options': [10, 20, 50, 100, 200]
      },
      item: null,
      user: {
        _id: null,
        user_name: null,
        password: null,
      },
      data4: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ['_id'],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null
      },
      selectedUser:[],
      suspendDialog : false,
      waiting:false,
      limitConfig: {},
      configUserData: {},
      limitHighLevelUser: {},
      configHighLevelUserCompany: {},
      dialogConfigHighLevelUserCompany:false,
      loading:false,
      dialogConfigUserData: false,
      dialogConfigHighLevelUser: false,
      dialogChangePassword: false,
    }
  },
  computed: {
    headers() {
      return [
        {text: this.$t('header.user_name'), align: 'start', sortable: false, value: 'user_name'},
        { text: this.$t('header.bank_account'), value: 'account_info' },
        { text: this.$t('header.balance'), value: 'balance' },
        { text: this.$t('header.role'), value: 'role_name' },
        { text: this.$t('header.created_at'), value: 'updatedAt' },
        { text: this.$t('header.active'), value: 'enable' },
                { text: this.$t("header.updateConfig"), value: "updateConfigUser" },
        { text: this.$t('header.actions'), value: 'actions', sortable: false }
      ];
    },
  },
  methods: {
      currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
    getUserChild4(item) {
      this.item = item
      this.data4.parentId = this.item._id
      this.data4.sortByFormat.length < 1 ? this.data4.sortBy = '_id' : this.data4.sortBy = this.data4.sortByFormat[0]
      this.data4.descendingFormat.length > 0 ? this.data4.descending = this.data4.descendingFormat[0] : this.data4.descending = true
      this.$request.post({
        url: 'users/getAllByUserId',
        data: this.data4
      }).then((res) => {
        if (res.data.code) {
          this.userChild4 = res.data.data
          this.dialogListUser = true
        }
      })
    },
     _openDialogSuspend(item){
      this.selectedUser = item
      this.suspendDialog = true
      
    },
     _closeDialogSuspend(){
      this.selectedUser = []
      this.suspendDialog = false
    },
    closeDialogChangePassword() {
      this.dialogChangePassword = false;
    },
    onDialogChangePassword(item) {
      this.user = {
        _id: item._id,
        user_name: item.user_name,
        password: null,
      };
      
      this.dialogChangePassword = true;
    },
    async onChangePassword() {
      this.loading = true;
      if (this.user._id) {
        
        await this.changePassword({ vm: this, data: this.user });
        this.loading = false;
        this.dialogChangePassword = false;
      }
    },
    async _updateUserSuspendStatus(){
      this.waiting = true;
      let data = {
        enable : !this.selectedUser.enable,
        userId: this.selectedUser._id// channel_type : this.channel_type
      }
      await this.$request
        .post({
          url: "/users/suspendUserTreeDown",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
           this.$emit('getData3')
            this._closeDialogSuspend()
            this.waiting = false;
          }else{
            this.$emit('getData3')
            this._closeDialogSuspend()
            this.waiting = false;
            
          }
        })},
     // Config User
    _openDailogConfig(item){
      let data = {user_id: item._id };
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompany = res.data.data;
          this.dialogConfigHighLevelUserCompany = true;
        });
    },
    async getAllLimitBetting(data) {
      
      this.$request
        .post({
          url: "limitBetting/getAll",
          data: data,
        })
        .then((res) => {
          this.limitHighLevelUser = res.data.data.object;
          this.getLimitData(data);
          this.dialogConfigHighLevelUser = true;
        });
    },
    async getLimitData(item) {
      let data = {
        currency_type: item.currency_type,
        _id: item.parent_id,
      };
      const response = await this.$request.post({
        url: "limitBetting/getParentLimitBetting",
        data: data,
      });
      this.limitConfig = response.data.data.object;
    },
    async onChangeConfigHighLevelUser() {
      this.loading = true;
      let formatData = this.formatHighLevelUserData();
      if (formatData._id) {
        await this.updateLimitSetting({
          vm: this,
          data: formatData
        });
        this.loading = false;
        this.dialogConfigHighLevelUser = false;
      }
    },
    closeConfigHighLevelUserDialog() {
      this.dialogConfigHighLevelUser = false;
    },

    async onChangeConfigUser() {
      this.loading = true;
      this.configUserData.total_bet_a_fight = parseInt(
        this.configUserData.total_bet_a_fight
      );
      this.configUserData.total_bet_win_a_day = parseInt(
        this.configUserData.total_bet_win_a_day
      );
      this.configUserData.amount_min = parseInt(
        this.configUserData.amount_min
      );

      this.configUserData.amount_max = parseInt(  
        this.configUserData.amount_max
      );
      if (this.configUserData._id) {
        await this.updateConfigUser({ vm: this, d: this.configUserData });
        this.loading = false;
        this.dialogConfigUserData = false;
      }
      this.$emit('getData1')
    },
    formatHighLevelUserData(){
      let data = this.limitHighLevelUser;
      data.total_bet_win_a_day = parseInt(
        this.limitHighLevelUser.total_bet_win_a_day
      );
      data.total_bet_a_fight = parseInt(
        this.limitHighLevelUser.total_bet_a_fight
      );
       data.amount_min = parseInt(
        this.limitHighLevelUser.amount_min
      );
       data.amount_max = parseInt(
        this.limitHighLevelUser.amount_max
      );
    return data;
    },

    async upConfigUser(data) {
      this.$request
        .post({
          url: "configUser/getConfigData",
          data: data,
        })
        .then((res) => {
          this.configUserData = res.data.data.object;
          this.getLimitData(data);
          this.dialogConfigUserData = true;
        });
      
    },
    closeConfigUserDialog() {
      this.dialogConfigUserData = false;
    },
        ...mapActions("$_configUser", [
      "updateLimitSetting",
      "updateConfigUser"
    ]),
    ...mapActions("$_user", [
      "changePassword",
    ]),
  }
}
</script>

<style scoped>

</style>
