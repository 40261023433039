<template>
  <div v-if="dialog && dataConfig">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            {{ $t("title.user_config_betting") }}

          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="loading" text @click="validate">
              {{ $t("button.save") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div v-if="userp && (userp.role_id =='611532b19c6e77ad0fd50c1c' ||  userp.role_id =='612a3fcc1a1fcead8871e822' )">
                <span class="text">ចំនួនដងនៃការចាក់</span>
               <v-text-field
                class="mt-5"
                v-model="dataConfig.betting_frequency"
                type="number"
              />
              </div>
              <span class="text">កំណត់ឈ្នះក្នុង១ថ្ងៃធំបំផុត</span>
              <span class="pl-4 limit-text">
                {{ currencyFormatKh(limitConfig.total_bet_win_a_day) }}
              </span>
               <vuetify-money
                class="mt-5"
                v-model="dataConfig.total_bet_win_a_day"
                v-bind:options="options"
                type="number"
              />

              <span class="text"> កំណត់ភ្នាល់ក្នុង១ដៃធំបំផុត </span>
              <span class="pl-4 limit-text">
                {{ currencyFormatKh(limitConfig.total_bet_a_fight) }}</span
              >
              <vuetify-money
                class="mt-5"    
                v-bind:options="options"
                v-model="dataConfig.total_bet_a_fight"
                type="number"
              />
              <span class="text"> ប្រាក់ភ្នាល់តូចបំផុតក្នុង១ប៉ុង </span>
              <span class="pl-4 limit-text">
                {{
                  dataConfig.currency_type == 1
                    ? 1
                    : dataConfig.currency_type == 2
                    ? 5000
                    : dataConfig.currency_type == 3
                    ? 50
                    : "loading.."
                }}</span
              >
              <vuetify-money
                class="mt-5"    
                v-bind:options="options"
                v-model="dataConfig.amount_min"
                type="number"
              />
              <span class="text"> ប្រាក់ភ្នាល់ធំបំផុតក្នុង១ប៉ុង</span>
              <span class="pl-4 limit-text">
                {{ currencyFormatKh(limitConfig.amount_max) }}</span
              >
             <vuetify-money
                class="mt-5"    
                v-bind:options="options"
                v-model="dataConfig.amount_max"
                type="number"
              />
              <span class="text"> Select Operation</span>
              <v-col col="12">
                <v-spacer v-if="showChannel1"></v-spacer>
                <v-checkbox
                  v-if="showChannel1"
                  v-model="selectedCh.channel_1"
                  color="green"
                  :label="$t('input.channel1')"
                ></v-checkbox>
                <v-spacer v-if="showChannel2"></v-spacer>
                <v-checkbox
                  v-if="showChannel2"
                  v-model="selectedCh.channel_2"
                  color="green"
                  :label="$t('input.channel2')"
                ></v-checkbox>
                <v-spacer v-if="showChannel3"></v-spacer>
                <v-checkbox
                  v-if="showChannel3"
                  v-model="selectedCh.channel_3"
                  color="green"
                  :label="$t('input.channel3')"
                ></v-checkbox>
                <v-spacer v-if="showChannel4"></v-spacer>
                <v-checkbox
                  v-if="showChannel4"
                  v-model="selectedCh.channel_4"
                  color="green"
                  :label="$t('input.channel4')"
                ></v-checkbox>
                <v-spacer></v-spacer>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: function () {
        return {
          _id: null,
          user_name: null,
          password: null,
        }
      }
    },
    userp: {
      type: Object,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          password: [
            (v) => !!v || "Password is required",
            (v) =>
              (!!v && v.length > 5) ||
              "Password must be greater than 5 characters",
          ],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    dataConfig: {
      type: Object,
      default: Object,
    },
    limitConfig: {
      type: Object,
      default: Object,
    },
  },
  watch: {
    dataConfig: function (newV) {
      this.correctSelectChanel(newV);
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
      
    },
    showChannel1() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 1) {
          return true;
        }
      }
      return false;
    },
    showChannel2() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 2) {
          return true;
        }
      }
      return false;
    },
    showChannel3() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 3) {
          return true;
        }
      }
      return false;
    },
    showChannel4() {
      for (let j in this.limitConfig.channel_allow) {
        if (this.limitConfig.channel_allow[j].channel_type == 4) {
          return true;
        }
      }
      return false;
    },
    
  },
  data: () => {
    return {
      showPassword: false,
      selectionChannelu1: [],
      selectedChannelu1: [],
      selectionChannelu2: [],
      selectedChannelu2: [],
      selectionChannelu3: [],
      selectedChannelu3: [],
      selectionChannelu4: [],
      selectedChannelu4: [],
      selectionChannel: [],
      options: {
      locale: "integer",
      length: 21,
      precision:0 
    },
    selectedCh: [],
      vipChanel1: [
        {
          id: 11,
          vip_option: 1,
          channel_type: 1,
        },
      ],
      vipChanel2: [
        {
          id: 21,
          vip_option: 1,
          channel_type: 2,
        },
      ],

      vipChanel3: [
        {
          id: 31,
          vip_option: 1,
          channel_type: 3,
        },
      ],
      vipChanel4: [
        {
          id: 41,
          vip_option: 1,
          channel_type: 4,
        },
      ],
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.dataConfig.channel_allow = [];
        if (this.selectedCh != null) {
          if (this.selectedCh.channel_1 && this.showChannel1) {
            this.dataConfig.channel_allow  = this.dataConfig.channel_allow.concat(this.vipChanel1);
          }
          if (this.selectedCh.channel_2 && this.showChannel2) {
            this.dataConfig.channel_allow =  this.dataConfig.channel_allow.concat(this.vipChanel2);
          }
          if (this.selectedCh.channel_3 && this.showChannel3) {
             this.dataConfig.channel_allow =  this.dataConfig.channel_allow.concat(this.vipChanel3);
          }
          if (this.selectedCh.channel_4 && this.showChannel4) {
             this.dataConfig.channel_allow =  this.dataConfig.channel_allow.concat(this.vipChanel4);
          }
        }

        this.$emit("submit");
        this.selectionChannel = [];
        this.selectionChannelu1 = [];
        this.selectedChannelu1 = [];
        this.selectionChannelu2 = [];
        this.selectedChannelu2 = [];
        this.selectionChannelu3 = [];
        this.selectedChannelu3 = [];
        this.selectionChannelu4 = [];
        this.selectedChannelu4 = [];
        this.dataConfig.channel_allow = [];
      }
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      return parseInt(num).toLocaleString("en");
    },
    correctSelectChanel(item) {
      this.selectedCh = [];
      for (let i in item.channel_allow) {
        if (item.channel_allow[i].channel_type == 1) {
          this.selectedCh.channel_1 = true;
        }
        if (item.channel_allow[i].channel_type == 2) {
          this.selectedCh.channel_2 = true;
        }
        if (item.channel_allow[i].channel_type == 3) {
          this.selectedCh.channel_3 = true;
        }
        if (item.channel_allow[i].channel_type == 4) {
          this.selectedCh.channel_4 = true;
        }
      }
    },
  },
};
</script>
<style scoped>
.limit-text {
  color: #ef9a9a;
  font-weight: bold;
  font-size: 20px;
}
.text {
  font-weight: bold;
  font-size: 20px;
}
</style>
