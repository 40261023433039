<template>
  <div v-if="dialog && dataConfig">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            {{ $t("title.user_limit_betting") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="loading" text @click="validate">
              {{ $t("button.save") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form v-if="dataConfig" ref="form" v-model="valid" lazy-validation>
              <v-alert text outlined color="deep-orange"> លុយ ដុល្លា </v-alert>

              <span class="text">កំណត់ចំនួនដងនៃការចាក់</span>
               <v-text-field
                class="mt-5"
                v-model="dataConfig.objectUsd[0].betting_frequency"
                type="number"
              />

              <span class="text"> កំណត់ឈ្នះក្នុង១ថ្ងៃធំបំផុត </span>
              <span class="pl-4 limit-text">{{
              currencyFormatKh(
              dataConfig.objectParentUsd[0].total_bet_win_a_day
              )
              }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectUsd[0].total_bet_win_a_day"
                type="number" />

              <span class="text">កំណត់ភ្នាល់ក្នុង១ដៃធំបំផុត</span>
              <span class="pl-4 limit-text">{{
              currencyFormatKh(
              dataConfig.objectParentUsd[0].total_bet_a_fight
              )
              }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectUsd[0].total_bet_a_fight"
                type="number" />
              <span class="text">ប្រាក់ភ្នាល់តូចបំផុតក្នុង១ប៉ុង</span>
              <span class="pl-4 limit-text">
                {{
                dataConfig.objectUsd[0].currency_type == 1
                ? 1
                : dataConfig.objectUsd[0].currency_type == 2
                ? currencyFormatKh(5000)
                : dataConfig.objectUsd[0].currency_type == 3
                ? 50
                : "loading.."
                }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectUsd[0].amount_min"
                type="number" />
              <span class="text"> ប្រាក់ភ្នាល់ធំបំផុតក្នុង១ប៉ុង</span>
              <span class="pl-4 limit-text">{{
              currencyFormatKh(dataConfig.objectParentUsd[0].amount_max)
              }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectUsd[0].amount_max"
                type="number" />
              <v-row class="mb-4">
                <v-treeview v-model="selectedChannelu1" selectable activatable return-object open-all
                  :items="this.itemChannelu1"></v-treeview>
                <v-treeview v-model="selectedChannelu2" selectable activatable return-object open-all
                  :items="this.itemChannelu2"></v-treeview>
                <v-treeview v-model="selectedChannelu3" selectable activatable return-object open-all
                  :items="this.itemChannelu3"></v-treeview>
                <v-treeview v-model="selectedChannelu4" selectable activatable return-object open-all
                  :items="this.itemChannelu4"></v-treeview>
              </v-row>

              <v-alert text outlined color="deep-orange"> លុយ ខ្មែរ </v-alert>
              <span class="text">កំណត់ចំនួនដងនៃការចាក់</span>
              <v-text-field class="mt-5" v-model="dataConfig.objectKh[0].betting_frequency" type="number" />
              <span class="text">កំណត់ឈ្នះក្នុង១ថ្ងៃធំបំផុត</span>
              <span class="pl-4 limit-text">{{
              currencyFormatKh(
              dataConfig.objectParentKh[0].total_bet_win_a_day
              )
              }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectKh[0].total_bet_win_a_day"
                type="number" />

              <!-- <span class="text">កំណត់ការភ្នាល់ក្នុង១ដៃចាប់ផ្តើម</span>
              <span class="pl-4 limit-text">
                {{
                  currencyFormatKh(dataConfig.objectKh[0].total_bet_a_fight)
                }}</span
              >
              <v-text-field
                class="mt-5"
                v-model="dataConfig.objectKh[0].default_bet_a_fight"
                type="number"
              /> -->

              <span class="text">កំណត់ភ្នាល់ក្នុង១ដៃធំបំផុត</span>
              <span class="pl-4 limit-text">{{
              currencyFormatKh(
              dataConfig.objectParentKh[0].total_bet_a_fight
              )
              }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectKh[0].total_bet_a_fight"
                type="number" />
              <span class="text">ប្រាក់ភ្នាល់តូចបំផុតក្នុង១ប៉ុង</span>
              <span class="pl-4 limit-text">
                {{
                dataConfig.objectKh[0].currency_type == 1
                ? 1
                : dataConfig.objectKh[0].currency_type == 2
                ? currencyFormatKh(5000)
                : dataConfig.objectKh[0].currency_type == 3
                ? 50
                : "loading.."
                }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectKh[0].amount_min"
                type="number" />
              <span class="text">ប្រាក់ភ្នាល់ធំបំផុតក្នុង១ប៉ុង</span>
              <span class="pl-4 limit-text">
                {{
                currencyFormatKh(dataConfig.objectParentKh[0].amount_max)
                }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectKh[0].amount_max"
                type="number" />
              <v-row class="mb-4">
                <v-treeview v-model="selectedChannelk1" selectable activatable return-object open-all
                  :items="itemChannelk1"></v-treeview>
                <v-treeview v-model="selectedChannelk2" selectable activatable return-object open-all
                  :items="itemChannelk2"></v-treeview>
                <v-treeview v-model="selectedChannelk3" selectable activatable return-object open-all
                  :items="this.itemChannelk3"></v-treeview>
                <v-treeview v-model="selectedChannelk4" selectable activatable return-object open-all
                  :items="this.itemChannelk4"></v-treeview>
              </v-row>
              <v-alert text outlined color="deep-orange"> លុយ ថៃបាត </v-alert>
              <span class="text">កំណត់ចំនួនដងនៃការចាក់</span>
              <v-text-field class="mt-5" v-model="dataConfig.objectTbs[0].betting_frequency" type="number" />
              <span class="text"> កំណត់ឈ្នះក្នុង១ថ្ងៃធំបំផុត </span>
              <span class="pl-4 limit-text">
                {{
                currencyFormatKh(
                dataConfig.objectParentTbs[0].total_bet_win_a_day
                )
                }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectTbs[0].total_bet_win_a_day"
                type="number" />
              <!-- <span class="text">កំណត់ភ្នាល់ក្នុង១ដៃ-ចាប់ផ្តើម</span>
              <span class="pl-4 limit-text">
                {{
                  currencyFormatKh(dataConfig.objectTbs[0].total_bet_a_fight)
                }}</span
              >
              <v-text-field
                class="mt-5"
                v-model="dataConfig.objectTbs[0].default_bet_a_fight"
                type="number"
              /> -->
              <span class="text">កំណត់ភ្នាល់ក្នុង១ដៃធំបំផុត</span>
              <span class="pl-4 limit-text">
                {{
                currencyFormatKh(
                dataConfig.objectParentTbs[0].total_bet_a_fight
                )
                }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectTbs[0].total_bet_a_fight"
                type="number" />
              <span class="text">ប្រាក់ភ្នាល់តូចបំផុតក្នុង១ប៉ុង</span>
              <span class="pl-4 limit-text">
                {{
                dataConfig.objectTbs[0].currency_type == 1
                ? 1
                : dataConfig.objectTbs[0].currency_type == 2
                ? currencyFormatKh(5000)
                : dataConfig.objectTbs[0].currency_type == 3
                ? 50
                : "loading.."
                }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectTbs[0].amount_min"
                type="number" />
              <span class="text"> ប្រាក់ភ្នាល់ធំបំផុតក្នុង១ប៉ុង</span>
              <span class="pl-4 limit-text">
                {{
                currencyFormatKh(dataConfig.objectParentTbs[0].amount_max)
                }}</span>
              <vuetify-money class="mt-5" v-bind:options="options" v-model="dataConfig.objectTbs[0].amount_max"
                type="number" />
              <v-row class="mb-4">
                <v-treeview v-model="selectedChannelt1" selectable activatable return-object open-all
                  :items="this.itemChannelt1"></v-treeview>
                <v-treeview v-model="selectedChannelt2" selectable activatable return-object open-all
                  :items="this.itemChannelt2"></v-treeview>
                <v-treeview v-model="selectedChannelt3" selectable activatable return-object open-all
                  :items="this.itemChannelt3"></v-treeview>
                <v-treeview v-model="selectedChannelt4" selectable activatable return-object open-all
                  :items="this.itemChannelt4"></v-treeview>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          password: [
            (v) => !!v || "Password is required",
            (v) =>
              (!!v && v.length > 5) ||
              "Password must be greater than 5 characters",
          ],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    dataConfig: {
      type: Object,
      default: Object,
    },
    limitConfig: {
      type: Object,
      default: Object,
    },
  },
  watch: {
    dataConfig: function (newV) {
      this.correctSelectChanel(newV.objectUsd[0]);
      this.correctSelectChanelKh(newV.objectKh[0]);
      this.correctSelectChanelTb(newV.objectTbs[0]);
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  data: () => {
    return {
      showPassword: false,
      options: {
        locale: "integer",
        length: 21,
        precision: 0,
      },
      itemChannelu1: [
        {
          id: 10,
          name: "Channel 1",
          children: [
            { id: "11", name: "Default", channel_type: 1, vip_option: 1 },
            { id: "12", name: "VIP 1", channel_type: 1, vip_option: 2 },
            { id: "13", name: "VIP 2", channel_type: 1, vip_option: 3 },
          ],
        },
      ],
      itemChannelu2: [
        {
          id: 20,
          name: "Channel 2",
          children: [
            { id: "21", name: "Default", channel_type: 2, vip_option: 1 },
            { id: "22", name: "VIP 1", channel_type: 2, vip_option: 2 },
            { id: "23", name: " VIP 2", channel_type: 2, vip_option: 3 },
          ],
        },
      ],
      itemChannelu3: [
        {
          id: 30,
          name: "Channel 3",
          children: [
            { id: "31", name: "Default", channel_type: 3, vip_option: 1 },
            { id: "32", name: "VIP 1", channel_type: 3, vip_option: 2 },
            { id: "33", name: " VIP 2", channel_type: 3, vip_option: 3 },
          ],
        },
      ],
      itemChannelu4: [
        {
          id: 40,
          name: "Channel 4",
          children: [
            { id: "41", name: "Default", channel_type: 4, vip_option: 1 },
            { id: "42", name: "VIP 1", channel_type: 4, vip_option: 2 },
            { id: "43", name: " VIP 2", channel_type: 4, vip_option: 3 },
          ],
        },
      ],
      itemChannelk1: [
        {
          id: 10,
          name: "Channel 1",
          children: [
            { id: "11", name: "Default", channel_type: 1, vip_option: 1 },
            { id: "12", name: "VIP 1", channel_type: 1, vip_option: 2 },
            { id: "13", name: "VIP 2", channel_type: 1, vip_option: 3 },
          ],
        },
      ],
      itemChannelk2: [
        {
          id: 20,
          name: "Channel 2",
          children: [
            { id: "21", name: "Default", channel_type: 2, vip_option: 1 },
            { id: "22", name: "VIP 1", channel_type: 2, vip_option: 2 },
            { id: "23", name: " VIP 2", channel_type: 2, vip_option: 3 },
          ],
        },
      ],
      itemChannelk3: [
        {
          id: 30,
          name: "Channel 3",
          children: [
            { id: "31", name: "Default", channel_type: 3, vip_option: 1 },
            { id: "32", name: "VIP 1", channel_type: 3, vip_option: 2 },
            { id: "33", name: " VIP 2", channel_type: 3, vip_option: 3 },
          ],
        },
      ],
      itemChannelk4: [
        {
          id: 40,
          name: "Channel 4",
          children: [
            { id: "41", name: "Default", channel_type: 4, vip_option: 1 },
            { id: "42", name: "VIP 1", channel_type: 4, vip_option: 2 },
            { id: "43", name: " VIP 2", channel_type: 4, vip_option: 3 },
          ],
        },
      ],
      itemChannelt1: [
        {
          id: 10,
          name: "Channel 1",
          children: [
            { id: "11", name: "Default", channel_type: 1, vip_option: 1 },
            { id: "12", name: "VIP 1", channel_type: 1, vip_option: 2 },
            { id: "13", name: "VIP 2", channel_type: 1, vip_option: 3 },
          ],
        },
      ],
      itemChannelt2: [
        {
          id: 20,
          name: "Channel 2",
          children: [
            { id: "21", name: "Default", channel_type: 2, vip_option: 1 },
            { id: "22", name: "VIP 1", channel_type: 2, vip_option: 2 },
            { id: "23", name: " VIP 2", channel_type: 2, vip_option: 3 },
          ],
        },
      ],
      itemChannelt3: [
        {
          id: 30,
          name: "Channel 3",
          children: [
            { id: "31", name: "Default", channel_type: 3, vip_option: 1 },
            { id: "32", name: "VIP 1", channel_type: 3, vip_option: 2 },
            { id: "33", name: " VIP 2", channel_type: 3, vip_option: 3 },
          ],
        },
      ],
      itemChannelt4: [
        {
          id: 40,
          name: "Channel 4",
          children: [
            { id: "41", name: "Default", channel_type: 4, vip_option: 1 },
            { id: "42", name: "VIP 1", channel_type: 4, vip_option: 2 },
            { id: "43", name: " VIP 2", channel_type: 4, vip_option: 3 },
          ],
        },
      ],

      selectionChannelu1: [],
      selectedChannelu1: [],
      selectionChannelu2: [],
      selectedChannelu2: [],
      selectionChannelu3: [],
      selectedChannelu3: [],
      selectionChannelu4: [],
      selectedChannelu4: [],
      selectionChannel: [],

      selectionChannelk1: [],
      selectedChannelk1: [],
      selectionChannelk2: [],
      selectedChannelk2: [],
      selectionChannelk3: [],
      selectedChannelk3: [],
      selectionChannelk4: [],
      selectedChannelk4: [],
      selectionChannelk: [],

      selectionChannelt1: [],
      selectedChannelt1: [],
      selectionChannelt2: [],
      selectedChannelt2: [],
      selectionChannelt3: [],
      selectedChannelt3: [],
      selectionChannelt4: [],
      selectedChannelt4: [],
      selectionChannelt: [],
    };
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.dataConfig.objectUsd[0].channel_allow =
          this.selectionChannel.concat(
            this.selectedChannelu1,
            this.selectedChannelu2,
            this.selectedChannelu3,
            this.selectedChannelu4
          );
        this.dataConfig.objectKh[0].channel_allow =
          this.selectionChannelk.concat(
            this.selectedChannelk1,
            this.selectedChannelk2,
            this.selectedChannelk3,
            this.selectedChannelk4
          );
        this.dataConfig.objectTbs[0].channel_allow =
          this.selectionChannelt.concat(
            this.selectedChannelt1,
            this.selectedChannelt2,
            this.selectedChannelt3,
            this.selectedChannelt4
          );
        this.$emit("submit");
        // for usd
        this.selectionChannel = [];
        this.selectionChannelu1 = [];
        this.selectedChannelu1 = [];
        this.selectionChannelu2 = [];
        this.selectedChannelu2 = [];
        this.selectionChannelu3 = [];
        this.selectedChannelu3 = [];
        this.selectionChannelu4 = [];
        this.selectedChannelu4 = [];
        // for kh
        this.selectionChannelk = [];
        this.selectionChannelk1 = [];
        this.selectedChannelk1 = [];
        this.selectionChannelk2 = [];
        this.selectedChannelk2 = [];
        this.selectionChannelk3 = [];
        this.selectedChannelk3 = [];
        this.selectionChannelk4 = [];
        this.selectedChannelk4 = [];
        // for tb
        this.selectionChannelt = [];
        this.selectionChannelt1 = [];
        this.selectedChannelt1 = [];
        this.selectionChannelt2 = [];
        this.selectedChannelt2 = [];
        this.selectionChannelt3 = [];
        this.selectedChannelt3 = [];
        this.selectionChannelt4 = [];
        this.selectedChannelt4 = [];
      }
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    correctSelectChanel(item) {
      for (let i in item.channel_allow) {
        if (
          item.channel_allow[i].channel_type == 1 &&
          item.currency_type == 1
        ) {
          this.selectionChannelu1.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (
          item.channel_allow[i].channel_type == 2 &&
          item.currency_type == 1
        ) {
          this.selectionChannelu2.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (
          item.channel_allow[i].channel_type == 3 &&
          item.currency_type == 1
        ) {
          this.selectionChannelu3.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (
          item.channel_allow[i].channel_type == 4 &&
          item.currency_type == 1
        ) {
          this.selectionChannelu4.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
      }

      this.selectedChannelu1 = this.selectionChannelu1;
      this.selectedChannelu2 = this.selectionChannelu2;
      this.selectedChannelu3 = this.selectionChannelu3;
      this.selectedChannelu4 = this.selectionChannelu4;
    },
    correctSelectChanelKh(item) {
      for (let i in item.channel_allow) {
        if (
          item.channel_allow[i].channel_type == 1 &&
          item.currency_type == 2
        ) {
          this.selectionChannelk1.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (
          item.channel_allow[i].channel_type == 2 &&
          item.currency_type == 2
        ) {
          this.selectionChannelk2.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (
          item.channel_allow[i].channel_type == 3 &&
          item.currency_type == 2
        ) {
          this.selectionChannelk3.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (
          item.channel_allow[i].channel_type == 4 &&
          item.currency_type == 2
        ) {
          this.selectionChannelk4.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
      }

      this.selectedChannelk1 = this.selectionChannelk1;
      this.selectedChannelk2 = this.selectionChannelk2;
      this.selectedChannelk3 = this.selectionChannelk3;
      this.selectedChannelk4 = this.selectionChannelk4;
    },
    correctSelectChanelTb(item) {
      for (let i in item.channel_allow) {
        if (
          item.channel_allow[i].channel_type == 1 &&
          item.currency_type == 3
        ) {
          this.selectionChannelt1.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (
          item.channel_allow[i].channel_type == 2 &&
          item.currency_type == 3
        ) {
          this.selectionChannelt2.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (
          item.channel_allow[i].channel_type == 3 &&
          item.currency_type == 3
        ) {
          this.selectionChannelt3.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (
          item.channel_allow[i].channel_type == 4 &&
          item.currency_type == 3
        ) {
          this.selectionChannelt4.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                  ? "Vip 1"
                  : item.channel_allow[i].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
      }

      this.selectedChannelt1 = this.selectionChannelt1;
      this.selectedChannelt2 = this.selectionChannelt2;
      this.selectedChannelt3 = this.selectionChannelt3;
      this.selectedChannelt4 = this.selectionChannelt4;
    },
  },
};
</script>
<style scoped>
.limit-text {
  color: #ef9a9a;
  font-weight: bold;
  font-size: 20px;
}

.text {
  font-weight: bold;
  font-size: 20px;
}
</style>
