<template>
  <div v-if="dialog && user">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            {{ $t('title.change_password_user') }} ({{ user.user_name }})
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="loading"  text @click="validate">
              {{ $t('button.save') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading"/>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
              <v-text-field
                  class="mt-5"
                  v-model="user.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :label="$t('input.password')"
                  :rules="rules.password"
                  @click:append="showPassword = !showPassword"
              />
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    darkmode: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    rules: {
      type: Object,
      default: function () {
        return {
          password: [
            v => !!v || 'Password is required',
            v => !!v && v.length > 5 || 'Password must be greater than 5 characters',
          ]
        }
      }
    },
    isValidate: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      default: function () {
        return {
          _id: null,
          user_name: null,
          password: null,
        }
      }
    }
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate
      },
      // setter
      set: function (newValue) {
        this.$emit('update:isValidate', newValue)
      }
    }
  },
  data: () => {
    return {
      showPassword: false
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('submit')
      }
    }
  }
}

</script>
